<template lang="pug">
.absolute.bottom-5.top-5.left-10.z-30.bg-white.shadow.grid.grid-cols-3.pr-4.pl-6.pt-5.ml-12(
  style='grid-template-rows: repeat(3, auto) minmax(0, 1fr); width:460px'
)
  .text-darkblue.col-start-1.col-end-3.text-xl.font-bold.font-SourceSansPro.whitespace-no-wrap {{ $t("reports.add-table-to-reports") }} 
  .flex.justify-end.col-start-3
    i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
      @click='$emit("close")'
    )
  .col-start-2.mb-3.flex.justify-center.col-start-3.col-end-4
    skif-button(@click='addToTemplate') {{ $t("add") }}
  .col-start-1.col-end-4.border-t.border-darkblue
    skif-search.w-64.mt-3(v-model='filterString' :placeholder='$t("search")' @searching="searching")
  .overflow-y-auto.mt-2.col-start-1.col-end-4
    el-tree(
      ref='tree',
      node-key='id',
      :data='filterData',
      :props='defaultProps',
      show-checkbox
    )
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['templateId'],
  data() {
    return {
      filterString: '',
      defaultProps: {
        children: 'template_tables',
        label: 'name'
      }
    }
  },
  computed: {
    ...mapGetters('reports', { templatesForAdding: 'templatesForAdding' }),
    filterData() {
      console.log('this.templatesForAdding', this.templatesForAdding)
      return this.templatesForAdding.filter(
        (template) =>
          JSON.stringify(template)
            .toLowerCase()
            .includes(this.filterString.toLowerCase())
        // &&
        // template.id !== this.templateId
      )
    }
  },
  methods: {
    searching(val) {
      this.filterString = val
    },
    addToTemplate() {
      const checkNodes = this.$refs.tree.getCheckedNodes().filter((node) => {
        console.log(node)
        return !node.template_tables
      })
      if (checkNodes.length === 0) {
        this.$showError('Выберите таблицу!')
        return
      }
      this.$emit('added-tables', checkNodes)
    }
  }
}
</script>

<style lang="stylus" scoped>
.middle-side {
  padding: 37px 0px;
  margin: 0 2px;
  height: 100%;
}
</style>
